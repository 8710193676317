import React from "react";


const BoardTile = ({value, type}) => {
        return (
            <div className={`tile ${type}`}>{value}</div>
        )
}


export default BoardTile;
