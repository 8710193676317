import React from "react";
import BoardTile from "./BoardTile";


const BoardRow = ({guess, currentGuess, wordLength}) => {
    
    if (guess) {
        return (
            <div className="row past">
                {guess.map((v, i) => <BoardTile key={i} value={v.letter} type={v.type}/>)}
            </div>
        )
    } 
    
    if (currentGuess) {
        return (
            <div className="row active">
                {[...currentGuess].map((v, i) => <BoardTile key={i} value={v} type="filled"/>)}
                {[...Array(wordLength - currentGuess.length)].map((v, i) => <BoardTile key={i} value="" type="empty"/>)}
            </div>

        )
    }

    return (
        <div className="row empty">
            {[...Array(wordLength)].map((v, i) => <BoardTile key={i} value="" type="empty"/>)}
        </div>
    )
}


export default BoardRow;
