import logo from "./logo.svg";
import "./App.css";
import Game from "./components/Game";


const App = () => {
  return (
    <>
      <header>
        {/* <button id="settings">Settings</button> */}
      </header>

        <main>
          <Game />
        </main>
    </>
  )
}

export default App;
