import { useState } from "react";


const useGame = (solution, validWords, wordLength) => {

  const [currentGuess, setCurrentGuess] = useState("");
  const [gameStatus, setGameStatus] = useState(null);
  const [guesses, setGuesses] = useState([...Array(6)]);
  const [showModal, setShowModal] = useState(false);
  const [turn, setTurn] = useState(0);
  const [usedLetters, setUsedLetters] = useState({});

  const resetGame = () => {
    setCurrentGuess("");
    setGameStatus(null);
    setGuesses([...Array(6)]);
    setShowModal(false);
    setTurn(0);
    setUsedLetters({});
  }


  const addNewGuess = () => {
    const newGuess = formatGuess();
    const updated = [...guesses];
    updated[turn] = newGuess;
    colorKeyboard(newGuess);
    setGuesses(updated);
  }


  const checkGuess = () => {
    setTurn(prev => prev + 1);

    const listenerWin = (e) => {
      const lasts = document.querySelectorAll(".tile.correct:last-child");
      if (e.animationName == "check" && e.target == lasts[lasts.length - 1]) {
        document.removeEventListener("animationend", listenerWin);
        setGameStatus("win");
        setShowModal(true);
     }
    }

    const listenerLose = (e) => {
      const lasts = document.querySelectorAll(".tile:last-of-type");
      if (e.animationName == "check" && e.target == lasts[lasts.length - 1]) {
        document.removeEventListener("animationend", listenerLose);
        setGameStatus("lose");
        setShowModal(true);
      }
    }
    
    if (currentGuess == solution) {
      setCurrentGuess("");
      // Wait to finish check animation before showing modal
      document.addEventListener("animationend", listenerWin);
    }

    else if (turn == 5) {
      // Wait to finish check animation before showing modal
      document.addEventListener("animationend", listenerLose);
    }

    else {
      setCurrentGuess("");
    }
  }

  const colorKeyboard = (guess) => {
    const listenerKeys = (e) => {
      const lasts = document.querySelectorAll(".row.past > .tile:last-of-type");
      if (e.animationName == "check" && e.target == lasts[lasts.length - 1]) {
        document.removeEventListener("animationend", listenerKeys);

        const newLetters = {...usedLetters};

        guess.map(l => {
          const letter = l["letter"];
          const type = l["type"];

          // Letter is correct or hasn't been used yet
          if (type == "correct" || !Object.keys(newLetters).includes(letter)) {
            newLetters[letter] = type;
          }

          // Letter is misplaced and not yet marked correct
          else if (type == "misplaced" && newLetters[letter] != "correct") {
            newLetters[letter] = type;
          }
        })

        setUsedLetters(newLetters);
      }
    }
    document.addEventListener("animationend", listenerKeys);
  }

  const formatGuess = () => {
    const checkSolution = [...solution];
    const formattedGuess = [...currentGuess].map((letter) => {
      return {"letter": letter, "type": "wrong"}
    });

    // Colouring correct letters
    formattedGuess.forEach((l, idx) => {
      if (l.letter == checkSolution[idx]) {
        formattedGuess[idx]["type"] = "correct";
        checkSolution[idx] = null;
      }
    })

    // Colouring misplaced letters
    formattedGuess.forEach((l, idx) => {
      if (checkSolution.includes(l.letter) && l["type"] != "correct") {
        formattedGuess[idx]["type"] = "misplaced";
        checkSolution[checkSolution.indexOf(l.letter)] = null;
      }
    })
    
    return formattedGuess;
  }


  const handleInput = (e) => {
    if (!["lose", "win"].includes(gameStatus)) {
      let letter = e.key || e;
      letter = letter.toLowerCase();

      if (/^[a-zA-Z]$/.test(letter) && currentGuess.length < wordLength) {
        setCurrentGuess(prev => prev + letter); 
      } else if (letter == "backspace" && currentGuess.length > 0) {
        setCurrentGuess(prev => prev.slice(0,-1));
      } else if (letter == "enter" && currentGuess.length == wordLength) {
        if (validWords.includes(currentGuess)) {
          addNewGuess();
          checkGuess();
        } else {
          setShowModal(true)
          setTimeout(() => {
            setShowModal(false);
          }, 2000);
        }
      }
    }
  }
  return {currentGuess, guesses, gameStatus, turn, handleInput, usedLetters, resetGame, showModal, setShowModal}
}


export default useGame;
