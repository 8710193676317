import React, { useEffect } from "react";

const Modal = ({gameStatus, solution, resetGame, getSolution, currentGuess, setShowModal}) => {
    const newGame = () => {
        getSolution();
        resetGame();
    }

    const closeModal = () => {
        setShowModal(false);
    }

    const enter = (e) => {
        if (e.key == "Enter" && gameStatus) {
            newGame();
        }

        else if (e.key == "Enter" && !gameStatus) {
            closeModal();
        }
    }

    useEffect(() => {
        window.addEventListener("keyup", enter);
        return () => window.removeEventListener("keyup", enter);
    }, [enter])

    switch (gameStatus) {

        case "win":
            return (
                <div className="modal win">
                    <div className="modal__inner">
                        <span className="close" onClick={closeModal}>&times;</span>
                        <p id="result">Yu wini 🏆</p>
                        <button id="play_again" onClick={newGame}>Prei agen</button>
                    </div>
                </div>
            )

        case "lose":
            return (
                <div className="modal lose">
                    <div className="modal__inner">
                        <span className="close" onClick={closeModal}>&times;</span>
                        <p id="result">Yu lasi</p>
                        <p>A wortu na</p>
                        <p id="solution">{solution}</p>
                        <a id="wortubuku" target="_blank" href={`http://suriname-languages.sil.org/Sranan/National/SrananNLDictIndex.html?Dict/${solution[0]}.html`}>San na {solution}?</a>
                        <button id="play_again" onClick={newGame}>Prei agen</button>
                    </div>
                </div>
            )

        default:
            return (
                <div className="modal invalid">
                    <div className="modal__inner">
                        <span className="close" onClick={closeModal}>&times;</span>
                        <p id="result">{currentGuess} no de ini a wortubuku</p>
                    </div>
                </div>
            )
    }
}

export default Modal;
