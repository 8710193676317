import React from "react";
import KeyboardKey from "./KeyboardKey";


const KeyboardRow = ({row, usedLetters, handleInput}) => {
    return (
        <div className="keyrow">{row.map((v, i) => <KeyboardKey key={i} type={usedLetters[v] || "default"} value={v} handleInput={handleInput}/>)}</div>
    )
}


export default KeyboardRow;
