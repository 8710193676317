import React from "react";


const KeyboardKey = ({value, type, handleInput}) => {
    switch (value) {
        case 'backspace':
        case 'enter':
            return (
                <div key={value} className={`key ${type}`} data-key={value} onClick={() => handleInput(value)}>
                    <img src={`${value}.svg`} />
                </div>
                )

        default:
            return (
                <div key={value} className={`key ${type}`} data-key={value} onClick={() => handleInput(value)}>{value}</div>
            )
    }
}


export default KeyboardKey;
