import React from "react";
import KeyboardRow from "./KeyboardRow";


const Keyboard = ({usedLetters, handleInput}) => {
    const row1 = 'qwertyuiop'.split('');
    const row2 = 'asdfghjkl'.split('');
    const row3 = ['backspace'].concat('zxcvbnm'.split(''), ['enter']);

    return (
        <div id="keyboard">
            <KeyboardRow row={row1} usedLetters={usedLetters} handleInput={handleInput} />
            <KeyboardRow row={row2} usedLetters={usedLetters} handleInput={handleInput} />
            <KeyboardRow row={row3} usedLetters={usedLetters} handleInput={handleInput} />
        </div>
    )
}


export default Keyboard;
