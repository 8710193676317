import React from "react";
import BoardRow from "./BoardRow";


const Board = ({guesses, currentGuess, turn, wordLength}) => {
    return (
        <div id="board">
            {guesses.map((v, i) => {
                if (i === turn) {
                    return <BoardRow key={i} currentGuess={currentGuess} wordLength={wordLength} />
                }
                return <BoardRow key={i} guess={v} wordLength={wordLength} />
            })}
        </div>
    )
}


export default Board;
