import React, { useEffect, useState } from "react";
import useGame from "../hooks/useGame";
import Board from "./Board";
import Keyboard from "./Keyboard";
import Modal from "./Modal";
import data from "../data/dictionary.json";

const Game = () => {
    const [language, setLanguage] = useState(window.localStorage.getItem("language") || "sranan");
    const [wordLength, setWordLength] = useState(parseInt(window.localStorage.getItem("wordLength")) || 5);
    const [solution, setSolution] = useState(null);
    const [validWords, setValidWords] = useState(null);
  
    const getSolution = () => {
        const words = data[language][String(wordLength)];
        const randInt = Math.floor(Math.random() * words.length);
        const word = words[randInt];
        setSolution(word);
        setValidWords(words);
    }
    
    useEffect(() => {
      getSolution()
   }, [language, wordLength])
  
 
    const {currentGuess, guesses, gameStatus, turn, handleInput, resetGame, setGameStatus, usedLetters, showModal, setShowModal} = useGame(solution, validWords, wordLength);
    
    useEffect(() => {
        window.addEventListener('keyup', handleInput);
        return () => window.removeEventListener('keyup', handleInput);
    }, [handleInput])

    return (
        <>  
            <Board guesses={guesses} currentGuess={currentGuess} turn={turn} wordLength={wordLength}/>
            <Keyboard turn={turn} usedLetters={usedLetters} handleInput={handleInput} />
            {showModal && <Modal gameStatus={gameStatus} wordLength={wordLength} solution={solution} resetGame={resetGame} getSolution={getSolution} currentGuess={currentGuess} setShowModal={setShowModal} />}
        </>
    )
}


export default Game;
